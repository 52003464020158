import * as React from "react"
import { Link, HeadFC, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import {SEO} from "../components/seo"
import Intro from "../components/intro"
import Container from "../components/container"
import Kontakt from "../components/kontakt"

const intro = {
  heading: "Åh nej! <span>404</span>",
  description: "<h2>Noget gik galt...</h2>"
}

const textStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "4rem 0",
  maxWidth: "500px",
  margin: "auto",
  textAlign: "center"
} 

const NotFoundPage = () => {
  return (
    // <main style={pageStyles}>
    //   <h1 style={headingStyles}>Page not found</h1>
    //   <p style={paragraphStyles}>
    //     Sorry 😔, we couldn’t find what you were looking for.
    //     <br />
    //     {process.env.NODE_ENV === "development" ? (
    //       <>
    //         <br />
    //         Try creating a page in <code style={codeStyles}>src/pages/</code>.
    //         <br />
    //       </>
    //     ) : null}
    //     <br />
    //     <Link to="/">Go home</Link>.
    //   </p>
    // </main>
    <Layout>
      <Intro 
        heading={intro?.heading} 
        description={intro?.description} 
        sm 
      />
      
      <Container>
        <div style={textStyles}>
          <p>
            Der er desværre sket en fejl og den efterspurgte side kan ikke findes. 
          </p>
          <Link 
            to="/"
            className={`btn`}
            >
            Gå til forsiden
          </Link>
        </div>
      </Container>
      <Kontakt/>
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return (
    <SEO title={`404 - Noget gik galt! | ${site.siteMetadata.title}`} description={site.siteMetadata.description}/>
  )
}
